.card {
  figure {
    overflow: hidden;
    position: relative;
  }

  .card-img-top {
    transition: all .6s ease-in-out 0s;
    -webkit-transition: all .6s ease-in-out 0s;
    transform: scale(1) rotate(0);
    -webkit-transform: scale(1) rotate(0);
  }

  // hover effects
  &:hover {
    cursor: pointer;

    .card-img-top {
      //filter: brightness(80%);
      opacity: .9;

      transform: scale(1.1) rotate(2deg);
      -webkit-transform: scale(1.1) rotate(2deg);
    }
  }
}
