.review-collapse.collapse:not(.show) {
  display: block;
  height: 145px;
  overflow: hidden;
}

.review-collapse.collapsing {
  height: 145px;
}

a.review-collapse-btn {
  &.collapsed::after {
    content: '+ Show More';
  }
}

a.review-collapse-btn {
  &:not(.collapsed)::after {
    content: '- Show Less';
  }
}

.section-reviews {
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  h2 {
    margin-bottom: 40px;
  }

  svg {
    color: #fcb415;

    &.md {
      margin-left: 2px;
      margin-right: 2px;
      width: 24px;
      height: 24px;
    }

    &.lg {
      margin-left: 4px;
      margin-right: 4px;
      width: 48px;
      height: 48px;
    }
  }
}

.block-testimonial__main .block-testimonial__score {
  font-family: ubuntubold, sans-serif;
  font-size: 120px;
  line-height: 1.2;
  font-weight: 600;
}
