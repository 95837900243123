html {
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: $font-family-base;
  font-size: 17px;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  padding-top: 82px;
}

p {
  color: $text-color;
  font-size: $font-size-base;
  line-height: 1.8em;
//  font-family: $font-family-text;
  margin-bottom: $margin-default;
}

a > figure > picture > img:hover {
  filter: brightness(80%);
}

section {
  &.bg-dark {
    table, p, li, label, legend {
      color: $inverse-color;
    }

    h1, h2, h3, h4, h5, h6 {
      color: $inverse-color;

      &:after {
        background-color: $secondary-color;
      }
    }
  }

  &.bg-primary {
    table, p, li, label, legend {
      color: $inverse-color;
    }

    h1, h2, h3, h4, h5, h6 {
      color: $inverse-color;
    }
  }
}
