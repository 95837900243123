.form-control,
.form-control[readonly] {
  background-color: $inverse-color;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  color: #252525;
  font-family: $font-family-input;
  height: unset;
  line-height: 24px;
  padding: 9px 15px;
  font-size: $font-size-base;

  &:focus {
    border: none;
    color: $text-color;
    //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    font-family: $font-family-input;
    font-weight: lighter;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
    font-family: $font-family-input;
    font-weight: lighter;
  }
  &:-ms-input-placeholder  {
    color: $placeholder-color;
    font-family: $font-family-input;
    font-weight: lighter;
  }
  &.disabled, &:disabled {
    opacity: .8;
    &:hover {
      cursor: not-allowed;
    }
  }
}

div.form-control {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0;
  padding-right: 0;

  label {
    font-size: 1.5rem;
    font-family: $font-family-label;
    margin-bottom: 1rem;
  }
}

.form-group {
  margin-bottom: 2rem;
}

.form-group-check {
  display: flex;
  flex-wrap: wrap;

  .form-check {
    padding-top: 10px;
    padding-bottom: 20px;
    flex: 1 0 50%;

    @include media-breakpoint-up(md) {
      flex: 1 0 25%;
    }
  }
}

/* TEXTAREA */
textarea.form-control {
  height: 242px;
  resize: none;
  margin: 0;
  padding: 2rem;
}
