* {
  margin:0px;
  padding:0px;
  border:none;
  outline:none;
}

/***

====================================================================
	Global Settings
====================================================================

 ***/

body {
  font-family: 'Montserrat', sans-serif;
  font-size: $font-size-base;
  color:#656d70;
  line-height:1.8em;
  font-weight:400;
  background:#ffffff;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center top;
  -webkit-font-smoothing: antialiased;
}

.auto-container{
  position:static;
  max-width:1200px;
  padding:0px 15px;
  margin:0 auto;
}

/***

====================================================================
	Parallax Section
====================================================================

***/

.parallax-section{
  position:relative;
  padding:80px 0px;
  color:#ffffff;
  background-position:center center;
  background-size:cover;
  background-attachment:fixed;
  background-repeat:no-repeat;
}

.parallax-section:before{
  content:'';
  position:absolute;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.65);
  z-index:0;
}

.parallax-section .auto-container{
  position:relative;
  z-index:5;
}

.parallax-section h2{
  position:relative;
  font-size:48px;
  font-weight:700;
  text-transform:uppercase;
  margin-bottom:20px;
  line-height:1.2em;
}

.parallax-section h3{
  position:relative;
  font-size:28px;
  margin-bottom:30px;
}

.parallax-section .line{
  position:relative;
  width:100px;
  height:3px;
  background:#ffffff;
  margin:0px auto 40px;
}

.parallax-section .text{
  position:relative;
  font-family: 'Bitter', serif;
  font-size:16px;
  font-style:italic;
  margin-bottom:40px;
}

.parallax-section .theme-btn{
  margin:0px 12px 5px;
  padding:10px 25px;

}
