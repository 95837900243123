// heading tags

.lead-2 {
  border-left: 5px solid $secondary-color;
  padding-left: 20px;

  .lead-title {
    margin: 0;
    padding: 0;

    &:after {
      content: '';
      background-color: none;
      height: 0;
    }
  }

  .lead-footer {

  }
}
