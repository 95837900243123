.navbar {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: none;
  -webkit-box-shadow: none;
  padding: 0;

  &:before {
    background-color: $secondary-color;
    content:'';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 90px;
    z-index: 0;
  }

  @media (max-width: 575px) {
    &:before {
      width: 0;
    }

    .navbar-brand {
      margin-left: 0;
      margin-right: 0;
      padding-left: 30px;
    }
  }

  @media (min-width: 576px) {
    &:before {
      width: calc((100% - 480px) / 2);
    }

    .navbar-brand {
      padding: 0;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  @media (min-width: 768px) {
    &:before {
      width: calc((100% - 660px) / 2);
    }

    .navbar-brand {
      padding: 0;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  @media (min-width: 992px) {
    &:before {
      width: calc((100% - 900px) / 2);
    }

    .navbar-brand {
      padding: 0;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  @media (min-width: 1200px) {
    &:before {
      width: calc((100% - 1188px) / 2 + 10px);
    }

    .navbar-brand {
      padding: 0;
      margin-left: 0;
      margin-right: 30px;
    }
  }
}

.navbar .navbar-brand {
  background: $secondary-color;
  height: 90px;
  position: relative;

  img {
    //
  }

  &:after {
    background: $secondary-color;
    content: '';
    position: absolute;
    right: -30px;
    top: 0px;
    clip-path: polygon(0 0,100% 0,calc(100% - 30px) 100%,0 100%);
    height: 100%;
    width: 30px;
    z-index: 0;
  }
}

.navbar-title {
  display: block;
  font-size: 20px;
  padding-top: 20px;
  text-transform: uppercase;
}

.navbar-subtitle {
  display: block;
  font-size: 12px;
  opacity: 0.8;
  text-transform: uppercase;
}

.navbar .navbar-nav .nav-item .nav-link {
  padding: 0 25px;
  height: 100%;
  line-height: 90px;
  font-size: 14px;
  text-transform: uppercase;

  &:link {
    color: $inverse-color;
  }

  &:visited {
    color: $inverse-color;
  }

  &:hover {
    color: $secondary-color;
  }

  &:active,  {
    color: $secondary-color;
  }
}

.nav-item {
  &.active {
    color: $secondary-color;
  }
}

.dropdown-menu {
  color: inherit;
  font-size: inherit;
  padding: 5px 0;
  background-color: #ffffff;
}

.dropdown-item {
  padding: 0px 25px;
  line-height: 40px;
}

.navbar-toggler {
  margin-right: 25px;
}

@media only all and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link {
    line-height: 42px !important;
  }
}
