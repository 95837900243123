// colors
$inverse-color: #FFFFFF;
$primary-color: #253d4a;
$secondary-color: #71b100;
$tertiary-color: #5B809F;
$text-color: #656d70;
$placeholder-color: #C7C7C7;
$text-muted: #cccccc;

// fonts
$font-family-title: 'Montserrat', sans-serif;
$font-family-text: sans-serif;
$font-family-input: 'Montserrat', sans-serif;
$font-family-label: sans-serif;
$font-family-light: 'Montserrat', sans-serif;
$font-family-bold: 'Montserrat', sans-serif;
$font-family-subtitle: 'Bitter', sans-serif;

// font sizes
$font-size-base: 1.4rem;

// margin
$margin-xs: 10px;
$margin-sm: 15px;
$margin-default: 20px;
$margin-lg: 25px;
$margin-xl: 30px;

// navbar
$navbar-height: 70px;
